.header {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  top: 0;
  box-shadow: 0px 4px 4px 0px #0000000d;
  background: linear-gradient(90deg, #515cc6 0%, #272d60 100%);
}

.header__logo {
  max-width: 415px;
  width: 100%;
  margin: 0 auto;
}

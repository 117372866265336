.main {
  margin: 0 auto;
  padding: 95px 0 45px;
}

.main__title {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Montserrat", sans-serif;
  font-size: 36px;
  font-weight: 700;
  line-height: 43.88px;
  letter-spacing: -0.035em;
  text-align: left;
  color: #ffffff;
  text-transform: uppercase;
}

.main__thumb {
  display: flex;
  flex-direction: column;
  gap: 22px;
  margin-top: 22px;
}

.main__item--wrapper {
  max-width: 390px;
  width: 100%;
  border: 1px solid #ffffff4d;
  border-radius: 20px;
}

.main__item {
  display: flex;
  justify-content: start;
  flex-direction: column;
  gap: 10px;
  max-width: 390px;
  width: 100%;
  height: 276px;
  padding: 14px;
  border-radius: 20px;
  background: linear-gradient(117deg, #515cc6 0%, #272d60 98.86%);
}

.main__logo {
  width: 57px;
  height: 57px;
}

.main__top--title {
  font-family: "Montserrat", sans-serif;
  font-size: 25px;
  font-weight: 600;
  line-height: 30.48px;
  text-align: left;
  color: #ffffff;
}

.main__stars {
  width: 97px;
}

.main__wrapper--top {
  display: flex;
  justify-content: space-between;
}

.main__wrapper--two {
  display: flex;
  gap: 16px;
}

.main__info--svg {
  width: 52px;
  height: 23px;
}

.main__button--text {
  font-family: "Montserrat", sans-serif;
  font-size: 21px;
  font-weight: 700;
  line-height: 23.1px;
  text-align: center;
  color: #00011a;

  text-transform: uppercase;
  cursor: pointer;
}

.main__button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  max-width: 362px;
  width: 100%;
  height: 90px;
  border-radius: 12px;
  background: #4affae;
  box-shadow: 0px 2px 10px -10px #4affae;

  border: transparent;
  cursor: pointer;
  font-family: "Montserrat", sans-serif;
  font-size: 21px;
  font-weight: 700;
  line-height: 23.1px;
  text-align: center;
}

.main__box--wrapper {
  display: flex;
  width: 100%;
}

.main__box--one {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 3px;
  width: 50%;
  height: 53px;
  background: #00011a;

  border-top-left-radius: 12px;
}

.main__box--two {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 3px;
  background: #161b4f;
  width: 50%;
  height: 53px;
  border-top-right-radius: 12px;
}

.main__box--three {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 28px;
  background: #e72e2e;
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
}

.main__three--text {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 17.6px;
  text-align: center;
  color: #ffffff;
}

.main__one--textTop {
  font-family: "Montserrat", sans-serif;
  font-size: 10px;
  font-weight: 600;
  line-height: 12.19px;
  text-align: center;
  color: #4affae;
}

.main__one--textBottom {
  display: flex;
  align-items: center;
  gap: 5px;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 23.1px;
  text-align: center;
  color: #4affae;
}

.main__two--textTop {
  font-family: "Montserrat", sans-serif;
  font-size: 10px;
  font-weight: 600;
  line-height: 12.19px;
  text-align: center;
  color: #41d3ad;
}

.main__two--textBottom {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 23.1px;
  text-align: center;
  color: #41d3ad;
}

.main__bonus {
  max-width: 362px;
  width: 100%;
  height: 81px;
  border-radius: 12px;
}

@media screen and (max-width: 350px) {
  .main__prize--top {
    font-size: 21px;
  }

  .main__prize--bottom {
    font-size: 13px;
  }
}

@media screen and (max-width: 340px) {
  .main__top--titleSmaller {
    font-size: 22px;
  }
}

@media screen and (min-width: 1000px) {
  .main__thumb {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 30px;
  }
}
